.userForm {
  display:flex;
  flex-direction: column;
  transition: 200ms all;
   > .helpText {
    margin-top: 1rem;
  }
  button {
    align-self: flex-end;
    margin-right: 0;
  }
  .inputDisplay {
    > div:first-of-type {
      display: none;
    }
    > div:nth-of-type(2) {
      > input {
        background-color: transparent;
        border-color: transparent;
        margin: 0px;
        padding: 0px;
      }
      ::placeholder {
        color: #4A4A4A;
        opacity: 1;
      }
    }
  }
}
