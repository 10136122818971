/**
*
* 'do' is the 'detailed-order' prefix
*
*/

.shipments-buttons-container {
  text-align: right;
}

.shipments-buttons-container button {
  display: inline-block;
}

.new-shipment-btn {
  display: inline-block;
}

.span-action-btn {
  button {
    margin: 0;
  }
}
.modal-shipments {
  border-radius: 7px;
  width: 40%;
}
.shipments-buttons-container > button,
.shipments-buttons-container > .span-action-btn {
  margin-left: 1rem !important;
  margin-right: 0 !important;
  margin-bottom: 12px;
}

@media (max-width: 39.9375em) {
  .shipments-row {
    margin-bottom: 52px;
  }
}
.pending-payment {
  font-size: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  color: red;
}

.detailed-order-menu {
  display: inline-block;

  .do-more-parent-container {
    position: relative;

    .do-more-button {
      margin-left: 0.5rem !important;
      margin: 0;
      background-color: rgba(37, 118, 218, 0.1);
      transition: 200ms;
      padding: 0 3px;
      transform: scale(1.2);
      border-radius: 3px;

      &:hover,
      &:active {
        background-color: rgba(37, 118, 218, 0.3);
      }
    }

    .dd-order-opts {
      position: absolute;
      z-index: 10;
      top: 8px;
      left: 24px;
      height: auto;
      max-height: 0;
      overflow: hidden;
      box-shadow: none;
      transition: 200ms;

      &.open {
        max-height: 250px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
          0 0 12px 7px rgba(208, 208, 208, 0.48);
      }

      img {
        height: 14px;
      }

      i,
      img {
        margin-left: 4px;
      }

      .ion-ios-copy-outline {
        color: #333;
      }

      .ion-android-open {
        color: #2576da;
      }
    }
  }

  .do-extra-controls {
    position: absolute;
    right: 0.5rem;
    top: 1rem;
    width: auto;

    .do-pagination {
      float: right;
      margin: 0;
      margin-right: 1rem;
      margin-top: 2px;
      background-color: rgba(37, 118, 218, 0.1);
      transition: 200ms;
      padding: 0 3px;
      transform: scale(1.2);
      border-radius: 6px;
      font-size: 20pt;
      box-sizing: border-box;
      color: #333;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
      border: none;
      outline: none;
      display: flex;
      height: 28px;
      width: 28px;
      justify-content: center;
      align-items: center;

      img {
        width: 16px;
      }

      &[type="disabled_link"] {
        background-color: #eee;
        cursor: not-allowed;
        pointer-events: none;
      }

      &:hover,
      &:active {
        background-color: rgba(37, 118, 218, 0.3);
      }
    }
  }
}

.detailed-order-container {
  p {
    color: #4a4a4a;
    margin-top: 0;
    margin-bottom: 0.2rem;
    display: block;

    &[type="microHeader"] {
      margin-top: 0.6rem;
    }
  }

  span[style] {
    margin-bottom: 0.35rem;
  }

  .seeMore {
    position: relative;
    width: 90%;
    margin-left: 10%;
    margin-bottom: 2rem;

    button {
      margin: 0;
      font-size: 10px;
    }

    &::after,
    .dot {
      position: absolute;
      height: calc(100% + 2rem);
      width: 4px;
      left: -26px;
    }

    &::after {
      content: "";
      top: 12px;
      background-color: #e6e6e6;
      z-index: 2;
    }

    .dot {
      background-color: white;
      height: 4px;
      z-index: 3;

      &:nth-of-type(1) {
        top: 2px;
      }

      &:nth-of-type(2) {
        top: 10px;
      }

      &:nth-of-type(3) {
        top: 18px;
      }
    }
  }

  .events-container {
    transition: 500ms ease-out;
    max-height: 0;
    height: auto;
    width: 100%;
    overflow: hidden;

    &.open {
      max-height: 900px;
      transition: 500ms ease-in;
      margin-bottom: -1rem;
    }
  }

  .indented-address {
    width: 90%;
    margin-left: 10%;
    margin-bottom: 2rem;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      box-sizing: border-box;
    }

    &.passed {
      &::before {
        background-color: #58ca77;
        border: none;
      }
    }

    &.incident {
      &::before {
        background-color: #fcbb3d;
        border: none;
      }
    }

    &::before {
      height: 16px;
      width: 16px;
      left: -32px;
      top: 2px;
      border-radius: 50px;
      background-color: white;
      border: 2px solid #e6e6e6;
      z-index: 3;
    }

    &::after {
      height: calc(100% + 2rem);
      width: 4px;
      left: -26px;
      top: 12px;
      background-color: #e6e6e6;
      z-index: 2;
    }

    &.without-track {
      &::after {
        content: none !important;
      }
    }
  }
}

.dd-automation-settings {
  width: 196px;
  left: 120px;
  top: -35px;
}

.checkout-details {
  .border-wrapper {
    border-bottom: #eceff4 2px solid;

    .next-steps {
      margin: 1rem 1rem;

      .title-header {
        font-size: 12px;
        color: black;
        font-weight: bold;
        margin-bottom: 0.5rem;

        button {
          font-size: 12px;
          float: right;
          margin-top: 0;
          margin-right: 0;

          i {
            font-size: 14px;
            padding-left: 10px;
          }
        }
      }

      p {
        text-align: justify;
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }

  .margin-wrapper {
    margin: 1rem 1rem;

    .title-orders {
      font-size: 12px;
      color: black;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    .list-orders {
      margin-bottom: 20px;
      .order {
        border: #eceff4 2px solid;
        padding: 0.5rem;
        margin-bottom: 1rem;

        .check-order {
          margin-top: 10px;
        }

        .img-invoice {
          padding-top: 8px;
          width: 30px;
          height: 30px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        .img-label {
          padding-top: 8px;
          padding-left: 5px;
        }

        .order-content {
          padding-top: 8px;
          color: #778092;

          img {
            height: 8px;
            margin-left: 5px;
            margin-right: 5px;
            color: #778092;
          }

          &.order-icon {
            img {
              height: 17px;
            }

            i {
              color: #2576da;
              font-size: 18px;
            }
          }
        }

        label {
          font-size: 12px;
        }
      }
    }

    .btn-send {
      margin-right: 0;
    }
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  width: 10px;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

/* New era */

.conditions-container {
  margin-bottom: 1rem;

  .add-filter-button-container {
    position: relative;
    display: inline;

    .add-filter-button {
      &.appending-filter {
        + div:not(.filter-dd) {
          display: inline-block !important;
        }
      }

      + div:not(.filter-dd) {
        display: none;
        margin-left: -1rem;
        transform: translateY(4px);
      }
    }
  }
}

.filter-dd {
  width: 132px;
  left: 0;

  .config-link {
    width: 100%;
    transition: 200ms;
    overflow: hidden;
  }

  .filter-container {
    max-height: 0;
    display: block;
    position: relative;
    height: auto;
    transition: 200ms;
    width: 100%;
    padding: 0.5rem 0.75rem 0 0.75rem;
  }

  &.selected {
    width: 240px;
    overflow: visible;

    &.filter-date {
      width: calc(450px + 1.5rem);
      max-height: 800px;

      .filter-container {
        max-height: 800px;
      }

      div.input-container.open {
        max-height: 500px !important;
      }
    }

    .config-link {
      width: 0;
      height: 0;
      padding: 0;

      p:after {
        background: transparent;
      }
    }

    .filter-container {
      max-height: 500px;

      .filter-title {
        color: #778092;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        transition: 200ms;
        box-sizing: border-box;
        vertical-align: middle;

        img {
          margin-right: 4px;
        }

        &:hover {
          padding-left: 16px;

          &:before {
            left: 12px;
            opacity: 1;
          }
        }

        &:before {
          font-family: "Ionicons";
          content: "\f3cf";
          color: #4a4a4a;
          font-size: 12pt;
          position: absolute;
          left: -8px;
          opacity: 0;
          transition: 200ms;
        }

        &:after {
          content: "";
          height: 1px;
          width: 100%;
          position: absolute;
          background-color: #eceff4;
          left: 0;
          bottom: 2.25rem;
        }
      }

      .fc-adapter {
        margin: 1rem 0;
      }

      *:not([type="link"]) {
        color: #4a4a4a;
      }

      .add-filter {
        width: 100%;
        box-sizing: border-box;
        margin: 0.75rem 0 !important;

        &:before {
          content: "";
          height: 1px;
          width: 100%;
          position: absolute;
          background-color: #eceff4;
          left: 0;
          top: 36px;
        }
      }

      ul {
        padding-top: 0.25rem;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 1rem;

        li {
          list-style-type: none;
          position: relative;
          color: #4a4a4a;
          margin-bottom: 0.25rem;

          div.input-container {
            height: auto;
            max-height: 0;
            overflow: hidden;
            transition: 200ms;

            &.open {
              max-height: 80px;
              width: calc(100% + 1rem);
              margin-left: -1rem;
              overflow: visible;
              > div {
                margin: 0 !important;

                p[type="label"] {
                  display: block;
                }
              }
            }
          }

          &:hover:before {
            background-color: #cad2df;
          }

          &.selected:before {
            content: "\f383";
            background-color: #2576da;
            border: 1px solid #2576da;
          }

          &:before {
            font-family: "Ionicons";
            color: white;
            font-size: 8px;
            text-align: center;
            content: "";
            transition: 200ms;
            height: 0.5rem;
            width: 0.5rem;
            border-radius: 100px;
            border: 1px solid #cad2df;
            display: block;
            position: absolute;
            left: -1rem;
            top: 0.25rem;
          }
        }
      }
    }
  }
}

.actions-btns {
  display: block;
  width: 100%;
  height: auto;
  max-height: 0;
  transition: 200;
  overflow: hidden;

  &.open {
    max-height: 100px;
  }
}

.card-shipments-container {
  text-align: center;
  .card-shipment {
    display: inline-block;
    text-align: left !important;
    padding: 1rem !important;
    margin-bottom: 1rem;
    position: relative;
    width: calc(100% - 2rem);

    .right-aligned {
      position: absolute;
      top: 1rem;
      right: 1rem;

      p {
        text-align: right;
      }
    }

    > div:not(.right-aligned) {
      margin-bottom: 0.75rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.marketplaces-tab {
  float: left;
}
.btn-gray-download {
  background: #eceff4;
  border: 1.5px solid #cad2df;
  color: #000000;
  border-radius: 2px;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  letter-spacing: 0.44px;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0px;
  width: auto;
}

.create-label-error {
  color: #cc162d;
  margin-bottom: 2em;
}
