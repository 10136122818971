.leadsPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // position: relative;

  .backgroundPage {
    top: 0vw;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;

    .dots {
      position: absolute;
      top: 0;
      left: 0;
    }

    .triangle {
      width: 100%;
      overflow: hidden;
      height: 100%;
    }
  }

  .logoContainer {
    width: 100%;
    z-index: 2;
    margin-bottom: 1em;

    width: 60%;
    max-width: 550px;

    .logo {
      width: 160px;
      img {
        width: 100%;
      }
    }
  }

  .card {
    background-color: white;
    z-index: 3;
    border-radius: 0.5em;
    width: 60%;
    max-width: 550px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 1px rgba(1, 1, 1, 0.2);
    padding: 2em 3em;
    transition: all 420ms ease;

    .title {
      font-size: 1.4em;
      color: #101646;
      font-weight: bold;
    }
    .colInput {
      display: flex;
      justify-content: space-between;

      .rowInput {
        width: 48%;
        transition: all 480ms ease;
      }
    }

    .tagLabel {
      margin-top: 1.5em;
      margin-bottom: 0.5em;
    }

    .btnSend {
      background-color: #eb2c88;
      margin: 0;
      margin-top: 3em;
      float: right;
      padding: 0.7em 1.5em;
      border-radius: 0.3em;

      &:disabled {
        background-color: rgba(100, 100, 100, 0.4);
        padding: 0.7em 1.5em;
        border-radius: 0.3em;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .card {
      width: 75%;
    }
  }

  @media screen and (max-width: 600px) {
    .card {
      width: 95%;
      padding: 2em 1.5em;

      .colInput {
        flex-wrap: wrap;

        .rowInput {
          width: 100%;
        }
      }
    }
  }
}
