.checkoutContainer {
  .modal {
    .modal {
      // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15) !important;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > div {
        position: initial;
        padding-right: 1em;
      }
    }
  }
  .checkoutContent {
    height: 100vh;
    width: 100% !important;
  }
}

.checkoutMainContainer {
  margin-top: 30px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 6.89748px rgba(0, 0, 0, 0.2),
    0px 0px 6.89748px rgba(0, 0, 0, 0.1);
  border-radius: 4.13849px;
  padding: 10px 50px 50px 50px;
  .checkoutTitleForm {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 42px;
    color: #363b43;
  }
  .numberGuides {
    color: #ff007e;
    letter-spacing: 0.489024px;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 15.3693px;
    line-height: 24px;
  }
  .subtitle {
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 1em;
    line-height: 23px;
    color: #303746;
  }
  .checkoutRenderShipmentsContainer {
    width: 100% !important;
    .checkoutRenderShipments {
      width: 100% !important;
      height: 100% !important;
      overflow-y: auto;
      .order {
        width: 100% !important;
        padding: 0 !important;
        .table {
          font-family: Muli;
          font-style: normal;
          font-weight: normal;
          line-height: 25px !important;
          letter-spacing: 0.838328px;
        }
      }
      .deleteContainer {
        text-align: end;
        .delete {
          width: fit-content !important;
          margin: 0 !important;
          font-family: Muli;
          font-style: normal;
          font-weight: 600;
          line-height: 23px;
          text-align: right;
          letter-spacing: 0.567618px;
        }
      }
    }
  }
  .total {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 1em;
    line-height: 13px;
    text-align: right;
    color: #4a4a4a;
  }
}

.title {
  align-items: center;
  text-align: center;
  color: #eb2c88;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-top: 0px;
}

.paymentIconsContainer {
  justify-content: space-between;
  width: 100%;
  .column:nth-of-type(2) {
    align-items: center;
    .paymentRectangle {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      .image {
        height: 132px;
      }
    }
  }
  .column:nth-of-type(3) {
    align-items: flex-end;
    .paymentRectangle {
      .image {
        margin-top: -11px;
        padding-bottom: 11px;
      }
    }
    justify-content: flex-end;
  }
  .column:first-of-type {
    align-items: flex-start;
  }
  .active {
    .paymentRectangle {
      border: 5px solid #101646 !important;
    }
    .paymentName {
      color: #101646 !important;
      text-align: center;
    }
  }
  .column {
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: flex;
    flex-direction: column;
    .paymentRectangle {
      border: 5px solid #c4c0c0;
      box-sizing: border-box;
      border-radius: 9px;
      width: 100%;
      background-color: #fff;
      margin-left: 0px;
      margin-right: 0px;
      .image {
        height: 90px;
      }
    }
    .paymentRectangle:first-of-type {
      padding-top: 30px;
    }
    .paymentName {
      align-self: center;
      font-family: Muli;
      font-style: normal;
      font-weight: bold;
      line-height: 30px;
      color: #c3c0c0;
    }
  }
}

.cardContainer {
  padding: 0px 0px 40px 0px;
  .card {
    box-shadow: 0px 0px 6.9px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 40px;
    padding-top: 30px;
    padding-bottom: 30px;

    padding-left: 48px;
    padding-right: 48px;
    padding-top: 40px;

    .cardOptions {
      .cardItem {
        height: 45px;
        .cardLogo {
          display: flex;
          justify-content: center;
        }
        .cardName {
          display: flex;
          align-items: center;
        }
      }
    }
    .balanceContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      .balance {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 38px;
        color: #4a4a4a;
      }
    }
    .walletTitle {
      font-family: Muli;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.655223px;
      color: #848484;
    }
    .walletSubtitle {
      font-family: Muli;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.655223px;
      color: #848484;
    }
    .walletText {
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      line-height: 26px;
      font-size: 15px;
      width: 80%;
      color: #4a4a4a;
      .walletLink {
        color: #eb2c88;
      }
    }
  }
}

.termsNConditionsContainer {
  display: flex;
  flex-direction: column;
  padding: 0 42px 0 42px;
  .checkbox {
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 28px;
    color: #697d91;
    input {
      margin-right: 7px;
    }
    p {
      display: flex;
      align-items: center;
    }
  }
  .americanExpressContainer {
    margin-top: -10px;
    .americanExpress {
      color: #697d91;
    }
  }
}

.informationContainer {
  padding-top: 100px;
  padding-bottom: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  .information {
    display: flex;
    flex-direction: column;
    width: 400px;
    align-items: flex-start;
    .title {
      font-family: Muli;
      font-style: normal;
      font-weight: bold !important;
      font-size: 17px !important;
      line-height: 22px !important;
      color: #848484 !important;
      margin-top: 19px;
      margin-bottom: 14px;
    }
    .text {
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 1em;
      line-height: 20px;
      color: #848484;
    }
  }
}

@media screen and (max-width: 756px) {
  .column:nth-of-type(3) {
    justify-content: flex-start !important;
  }
}

@media screen and (max-width: 500px) {
  .paymentRectangle {
    min-height: 120px;
    // padding: 0;

    img.image {
      width: 50px;
      height: 50px !important;
    }
  }

  .cardContainer {
    .card {
      padding: 40px 20px;

      p.walletText {
        width: 100%;
      }
    }
  }
}
