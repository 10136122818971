/*Invoices styles and animations*/
@keyframes invoicesPairMove {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-40px);
  }
}
@-webkit-keyframes invoicesPairMove {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-40px);
  }
}

@keyframes invoicesOddMove {
  from {
    transform: translateY(-40px);
  }
  to {
    transform: translateY(0px);
  }
}
@-webkit-keyframes invoicesOddMove {
  from {
    transform: translateY(-40px);
  }
  to {
    transform: translateY(0px);
  }
}

#invoices-0, #invoices-2 {
  width: 103px;
  height: 140px;
  object-fit: contain;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  animation: invoicesPairMove 1s infinite alternate;
  -webkit-animation: invoicesPairMove 1s infinite alternate;
}

#invoices-1, #invoices-3 {
  width: 103px;
  height: 140px;
  object-fit: contain;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  animation: invoicesOddMove 1s infinite alternate;
  -webkit-animation: invoicesOddMove 1s infinite alternate;
}

/*Addresses styles and animations*/
@keyframes addressFadeMove {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(214px);
    opacity: 0;
  }
}
@-webkit-keyframes addressFadeMove {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(214px);
    opacity: 0;
  }
}

@keyframes addressMove {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(214px);
  }
}
@-webkit-keyframes addressMove {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(214px);
  }
}

@keyframes addressAppear {
  from {
    transform: translateX(0px);
    opacity: 0;
  }
  to {
    transform: translateX(167px);
    opacity: 1;
  }
}
@-webkit-keyframes addressAppear {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(47px);
  }
}

#addresses-0 {
  width: 166px;
  height: 197px;
  margin-left: -214px;
  object-fit: contain;
  z-index: -10000;
  animation: addressAppear 1s infinite ease-in-out;
  -webkit-animation: addressAppear 1s infinite ease-in-out;
}

#addresses-1, #addresses-2 {
  width: 166px;
  height: 197px;
  margin-right: 47px;
  object-fit: contain;
  z-index: -10000;
  position: relative;
}

/*one goes in, one appears*/
#addresses-1 {
  animation: addressMove 1s infinite ease-in-out;
  -webkit-animation: addressMove 1s infinite ease-in-out;
}

#addresses-2 {
  animation: addressFadeMove 1s infinite ease-in-out forwards;
  -webkit-animation: addressFadeMove 1s infinite ease-in-out forwards;
}

#addresses-3 {
  width: 169px;
  height: 197px;
  object-fit: contain;
  z-index: 10000;
}

/*Shipments styles and animations*/
@keyframes shipmentFadeMove {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(120px);
    opacity: 0;
  }
}
@-webkit-keyframes shipmentFadeMove {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(120px);
    opacity: 0;
  }
}

@keyframes shipmentMove {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(129px);
  }
}
@-webkit-keyframes shipmentMove {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(129px);
  }
}

@keyframes shipmentAppear {
  from {
    transform: translateX(0px);
    opacity: 0;
  }
  to {
    transform: translateX(62px);
    opacity: 1;
  }
}
@-webkit-keyframes shipmentAppear {
  from {
    transform: translateX(0px);
    opacity: 0;
  }
  to {
    transform: translateX(62px);
    opacity: 1;
  }
}

#shipments-0 {
  width: 62px;
  height: 62px;
  margin-bottom: 35px;
  margin-left: -129px;
  object-fit: contain;
  animation: shipmentAppear 1s infinite ease-in-out;
  -webkit-animation: shipmentAppear 1s infinite ease-in-out;
}

#shipments-1, #shipments-2{
  width: 62px;
  height: 62px;
  margin-bottom: 35px;
  margin-right: 67px;
  position: relative;
  object-fit: contain;
  animation: shipmentMove 1s infinite ease-in-out forwards;
  -webkit-animation: shipmentMove 1s infinite ease-in-out forwards;
}

#shipments-3 {
  width: 62px;
  height: 62px;
  margin-bottom: 35px;
  margin-right: 49px;
  object-fit: contain;
  z-index: -1000;
  position: relative;
  animation: shipmentFadeMove 1s infinite ease-in-out forwards;
  -webkit-animation: shipmentFadeMove 1s infinite ease-in-out forwards;
}

#shipments-4 {
  width: 226px;
  height: 133px;
  object-fit: contain;
  z-index: 10000;
}

/*Cards styles and animations*/
#cards-0 {
  width: 94px;
  height: 116px;
  margin-right: 32px;
  object-fit: contain;
}


@keyframes cardsPointMove {
  from {
    transform: translateY(5px);
  }
  to {
    transform: translateY(-5px);
  }
}
@-webkit-keyframes cardsPointMove {
  from {
    transform: translateY(5px);
  }
  to {
    transform: translateY(-5px);
  }
}

#cards-1, #cards-2, #cards-3, #cards-4, #cards-5 {
  width: 17px;
  height: 17px;
  margin-bottom: 36px;
  margin-right: 27px;
  object-fit: contain;
}

#cards-1 {
  animation: cardsPointMove 0.5s infinite alternate;
  -webkit-animation: cardsPointMove 0.5s infinite alternate;
}
#cards-2 {
  animation: cardsPointMove 0.5s infinite alternate;
  -webkit-animation: cardsPointMove 0.5s infinite alternate;
  animation-delay: 0.1s;
}
#cards-3 {
  animation: cardsPointMove 0.5s infinite alternate;
  -webkit-animation: cardsPointMove 0.5s infinite alternate;
  animation-delay: 0.2s;
}
#cards-4 {
  animation: cardsPointMove 0.5s infinite alternate;
  -webkit-animation: cardsPointMove 0.5s infinite alternate;
  animation-delay: 0.3s;
}
#cards-5 {
  animation: cardsPointMove 0.5s infinite alternate;
  -webkit-animation: cardsPointMove 0.5s infinite alternate;
  animation-delay: 0.4s;
}



#cards-6 {
  margin-left: 5px;
  width: 140px;
  height: 90px;
  object-fit: contain;
}


/*Integrations styles and animations*/
@keyframes integrationsPairMove {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-40px);
  }
}
@-webkit-keyframes integrationsPairMove {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-40px);
  }
}

@keyframes integrationsOddMove {
  from {
    transform: translateY(-40px);
  }
  to {
    transform: translateY(0px);
  }
}
@-webkit-keyframes integrationsOddMove {
  from {
    transform: translateY(-40px);
  }
  to {
    transform: translateY(0px);
  }
}
#integrations-0 {
  width: 163.7px;
  height: 87.2px;
  margin-right: 56px;
  background-color: #eb2c88;
  margin-bottom: 35px;
}

#integrations-1 {
  width: 163.7px;
  height: 87.2px;
  margin-right: 43.3px;
  background-color: #eb2c88;
  margin-bottom: 35px;
}
#integrations-2 {
  width: 163.7px;
  height: 87.2px;
  background-color: #eb2c88;
  margin-bottom: 35px;
}

#integrations-0, #integrations-2 {
  animation: integrationsPairMove 1s infinite alternate;
  -webkit-animation: integrationsPairMove 1s infinite alternate;
}

#integrations-1 {
  animation: integrationsOddMove 1s infinite alternate;
  -webkit-animation: integrationsOddMove 1s infinite alternate;
}

.oval{
  width: 22.5px;
  height: 22.5px;
  opacity: 0.5;
  border-radius: 50%;
  border: solid 3px white;
  display: inline-block;
  margin-right: 25px;
  padding: 6.5px;
}

.checkmark{
  letter-spacing: 0;
  font-size: 22px;
  line-height: 22px;
  color: white;
  text-align: center;
}

#creating_integrations-0  {
  animation: integrationsFade 5s, integrationsMove 8s;
  -webkit-animation: integrationsFade 5s, integrationsMove 8s;
}

#creating_integrations-1  {
  animation: integrationsFade 5s 1s, integrationsMove 8s;
  -webkit-animation: integrationsFade 5s 1s, integrationsMove 8s;
}

#creating_integrations-2  {
  animation: integrationsFade 5s 2s, integrationsMove 8s;
  -webkit-animation: integrationsFade 5s 2s, integrationsMove 8s;
}

#creating_integrations-3  {
  animation: integrationsFade 5s 3s, integrationsMove 8s;
  -webkit-animation: integrationsFade 5s 3s, integrationsMove 8s;
}

#checkmark-0  {
  animation: tickingBox 5s ;
  -webkit-animation: tickingBox 5s;
}

#checkmark-1  {
  animation: tickingBox 5s 1s;
  -webkit-animation: tickingBox 5s 1s;
}

#checkmark-2  {
  animation: tickingBox 5s 2s;
  -webkit-animation: tickingBox 5s 2s;
}

#checkmark-3  {
  animation: tickingBox 5s 3s;
  -webkit-animation: tickingBox 5s 3s;
}

@keyframes integrationsMove {
  0% {
    transform: translateY(0px);
  }
  14% {
    transform: translateY(-26px);
  }
  29% {
    transform: translateY(-52px);
  }
  43% {
    transform: translateY(-78px);
  }
  57% {
    transform: translateY(-104px);
  }
  71% {
    transform: translateY(-130px);
  }
  86% {
    transform: translateY(-156px);
  }
  100% {
    transform: translateY(-182px);
  }
}
@-webkit-keyframes integrationsMove {
  100% {
    transform: translateY(-104px);
  }
}

@keyframes integrationsFade {
  0% {
    opacity:0.7;
  }
  25% {
    opacity:1.0;
  }
  50% {
    opacity:0.7;
  }
  75% {
    opacity:0.4;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes integrationsFade {
  0% {
    opacity:0.7;
  }
  25% {
    opacity:1.0;
  }
  50% {
    opacity:0.7;
  }
  75% {
    opacity:0.4;
  }
  100% {
    opacity:0;
  }
}

@keyframes tickingBox {
  0% {
    background-color: white;
    border: solid 3px #cad2df;
  }
  35% {
    background-color: #00bc71;
    border: solid 3px #00bc71;
    opacity:1;
  }
  75% {
    opacity:0.7;
  }
}
@-webkit-keyframes tickingBox {
  0% {
    background-color: white;
    border: solid 3px #cad2df;
  }
  35% {
    background-color: #00bc71;
    border: solid 3px #00bc71;
  }
}
