@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

.plansContainer {
  padding: 48px 48px 0 48px;
  background-color: white;
  min-height: calc(100vh - 48px);
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: bold;
      font-size: 35px;
      line-height:48px;
      color: #101646;
    }
    .row {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      .containerInputs {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        width: 300px;
        height: 440px;
        .card {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 18px 25px 18px 25px;
          .titlePlan {
            text-transform: uppercase;
            font-family: 'Inter';
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 18px;
          }
          .blue {
            color: #5D4FEB;
          }
          .pink {
            color: #FF288C;
          }
          .price {
            font-family: 'Inter';
            font-weight: bold;
            font-size: 33px;
            color: #101646 !important;
            line-height: 40px;
            margin-bottom: 10px;
            .perMonth {
              font-weight: 400;
              font-size: 18px;
              line-height: 17px;
            }
          }
          .currency {
            font-family: 'Inter';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #8190A5;
          }
          .information {
            display: flex;
            justify-content: center;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #101646;
            p {
              margin-top: -20px;
              width: 100%;
            }
          }
          .processing {
            display: flex;
            flex-direction: column;
            .grey {
              margin-top: -10px;
              background: #F2F2F2;
              border-radius: 5px;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 600;
              font-size: 10px;
              line-height: 12px;
              text-align: center;
              color: #101646;
              padding: 5px;
              margin-bottom: 10px;
            }
            .extra {
              font-family: 'Inter';
              font-style: normal;
              font-weight: normal;
              font-size: 8px;
              line-height: 8px;
              text-align: end;
              color: #101646;
              align-self: center;
            }
          }
          .buttonContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 50px;
            p {
              margin: 0 !important;
            }
          }
        }
      }
    }
    a {
      margin-top: 30px;
      color: #5D4FEB !important;
      text-decoration: none;
      width: 80%;
      cursor: pointer;
      .buttonComparison {
        font-family: 'Inter';
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 21px;
        text-align: center;
        color: #5D4FEB !important;
        border: 1px solid #5D4FEB;
        height: 147px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
      }
    }
    .cardNormal {
      background-color: #FAFAFA;
      border-radius: 10px;
      box-shadow: 0 0 0 0;
    }
    .cardWhite {
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
  }
  .iva {
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #8190A5;
  }
}

@media screen and (max-width: 1180px) {
  .row::after{
    content: '';
    width: 213px;
  }
}
@media screen and (max-width: 1591px) {
  .containerInputs {
    width: 213px !important;
  }
}
@media screen and (max-width: 519px) {
  .row {
    justify-content: center !important;
  }
}
