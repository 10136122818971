.upload-csv {
  .upload-title {
    color: #778092;
    font-size: 13px;
    font-weight: bold;
    background-color: inherit;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 2px solid #eceff4;

    .link-up {
      width: 196px;
      line-height: 42px;
      box-sizing: border-box;
      text-align: left;
      padding-left: 12px;
      padding-right: 12px;
      color: #778092 !important;
      display: inline;

      p {
        color: #778092 !important;
      }

      &:hover {
        background: #e2e2e2;
      }
    }
  }

  .dropzone-upload {
    margin-top: 10px;
    height: 210px;
    background: #f0f3f5;
    border-radius: 3px 3px 3px 3px;
    text-align: center;
    padding-top: 30px;
    color: #778092;

    p {
      margin: 0;
    }
  }

  .btn-download-file {
    background: transparent;
    border: none;
    color: #2576da;
    border-radius: 0px;
    padding: 0px 0px;
    -webkit-transition: background-color 0.05s ease-in-out;
    transition: background-color 0.05s ease-in-out;
    font-size: 13px;
    font-weight: 600;
    display: inline-block;
    -webkit-letter-spacing: 0.44px;
    -moz-letter-spacing: 0.44px;
    -ms-letter-spacing: 0.44px;
    letter-spacing: 0.44px;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    margin: 12px 0px;
    width: auto;
    font-family: Muli;
    text-decoration: none;
    cursor: pointer;
  }
}

.process-file {
  .title-process {
    color: #4a4a4a !important;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;

    i {
      color: #2576da;
    }
  }

  .list-tasks {
    p {
      .rot-left {
        margin-left: 8px;
        float: left;
        margin-right: 10px;
        color: #2576da;
      }
      img {
        width: 20px;
        margin-right: 10px;
      }

      display: block;
      margin: 20px 0px;
      .wrapper-div {
        display: inline;
      }
    }
  }
}

.confirm-file {
  .title-confirm {
    color: #4a4a4a !important;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .resume {
    margin-bottom: 20px;
    margin-left: 15px;

    p {
      display: block;
      color: #4a4a4a;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0.5px;
    }
  }

  .title {
    display: block;
    margin-bottom: 10px;
  }

  .error-list {
    margin-left: 15px;
    p {
      display: list-item;
      color: #4a4a4a;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0.5px;
    }
  }
}

.label-upload-csv-cont {
  padding: 0 0.5em;
}

.label-upload-csv.title {
  font-size: 14px;
  font-weight: bold;
}

ul.label-upload-csv {
  padding-left: 1.5em;
}

.label-upload-csv-li {
  margin-bottom: 1em;
  line-height: 2em;
  list-style: none;
}

.label-upload-csv {
  color: gray;
  font-size: 14px;
  display: block;
  line-height: 1.5em;
}
