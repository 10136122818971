/**
*
* 'do' is the 'detailed-order' prefix
*
*/
.shipments-buttons-container {
  text-align: right;
}

.shipments-buttons-container button {
  display: inline-block;
}

.new-shipment-btn {
  display: inline-block;
}

.span-action-btn button {
  margin: 0;
}

.shipments-buttons-container > button,
.shipments-buttons-container > .span-action-btn {
  margin-left: 1rem !important;
  margin-right: 0 !important;
  margin-bottom: 12px;
}

@media (max-width: 39.9375em) {
  .shipments-row {
    margin-bottom: 52px;
  }
}
.detailed-order-menu {
  display: inline-block;
}
.detailed-order-menu .do-more-parent-container {
  position: relative;
}
.detailed-order-menu .do-more-parent-container .do-more-button {
  margin-left: 0.5rem !important;
  margin: 0;
  background-color: rgba(37, 118, 218, 0.1);
  transition: 200ms;
  padding: 0 3px;
  transform: scale(1.2);
  border-radius: 3px;
}
.detailed-order-menu .do-more-parent-container .do-more-button:hover,
.detailed-order-menu .do-more-parent-container .do-more-button:active {
  background-color: rgba(37, 118, 218, 0.3);
}
.detailed-order-menu .do-more-parent-container .dd-order-opts {
  position: absolute;
  z-index: 10;
  top: 8px;
  left: 24px;
  height: auto;
  max-height: 0;
  overflow: hidden;
  box-shadow: none;
  transition: 200ms;
}
.detailed-order-menu .do-more-parent-container .dd-order-opts.open {
  max-height: 250px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
    0 0 12px 7px rgba(208, 208, 208, 0.48);
}
.detailed-order-menu .do-more-parent-container .dd-order-opts img {
  height: 14px;
}
.detailed-order-menu .do-more-parent-container .dd-order-opts i,
.detailed-order-menu .do-more-parent-container .dd-order-opts img {
  margin-left: 4px;
}
.detailed-order-menu
  .do-more-parent-container
  .dd-order-opts
  .ion-ios-copy-outline {
  color: #333;
}
.detailed-order-menu
  .do-more-parent-container
  .dd-order-opts
  .ion-android-open {
  color: #2576da;
}
.detailed-order-menu .do-extra-controls {
  position: absolute;
  right: 0.5rem;
  top: 1rem;
  width: auto;
}
.detailed-order-menu .do-extra-controls .do-pagination {
  float: right;
  margin: 0;
  margin-right: 1rem;
  margin-top: 2px;
  background-color: rgba(37, 118, 218, 0.1);
  transition: 200ms;
  padding: 0 3px;
  transform: scale(1.2);
  border-radius: 6px;
  font-size: 20pt;
  box-sizing: border-box;
  color: #333;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
  border: none;
  outline: none;
  display: flex;
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;
}
.detailed-order-menu .do-extra-controls .do-pagination img {
  width: 16px;
}
.detailed-order-menu .do-extra-controls .do-pagination[type="disabled_link"] {
  background-color: #eee;
  cursor: not-allowed;
  pointer-events: none;
}
.detailed-order-menu .do-extra-controls .do-pagination:hover,
.detailed-order-menu .do-extra-controls .do-pagination:active {
  background-color: rgba(37, 118, 218, 0.3);
}

.detailed-order-container p {
  color: #4a4a4a;
  margin-top: 0;
  margin-bottom: 0.2rem;
  display: block;
}
.detailed-order-container p[type="microHeader"] {
  margin-top: 0.6rem;
}
.detailed-order-container span[style] {
  margin-bottom: 0.35rem;
}
.detailed-order-container .seeMore {
  position: relative;
  width: 90%;
  margin-left: 10%;
  margin-bottom: 2rem;
}
.detailed-order-container .seeMore button {
  margin: 0;
  font-size: 10px;
}
.detailed-order-container .seeMore::after,
.detailed-order-container .seeMore .dot {
  position: absolute;
  height: calc(100% + 2rem);
  width: 4px;
  left: -26px;
}
.detailed-order-container .seeMore::after {
  content: "";
  top: 12px;
  background-color: #e6e6e6;
  z-index: 2;
}
.detailed-order-container .seeMore .dot {
  background-color: white;
  height: 4px;
  z-index: 3;
}
.detailed-order-container .seeMore .dot:nth-of-type(1) {
  top: 2px;
}
.detailed-order-container .seeMore .dot:nth-of-type(2) {
  top: 10px;
}
.detailed-order-container .seeMore .dot:nth-of-type(3) {
  top: 18px;
}
.detailed-order-container .events-container {
  transition: 500ms ease-out;
  max-height: 0;
  height: auto;
  width: 100%;
  overflow: hidden;
}
.detailed-order-container .events-container.open {
  max-height: 900px;
  transition: 500ms ease-in;
  margin-bottom: -1rem;
}
.detailed-order-container .indented-address {
  width: 90%;
  margin-left: 10%;
  margin-bottom: 2rem;
  position: relative;
}
.detailed-order-container .indented-address::before,
.detailed-order-container .indented-address::after {
  content: "";
  position: absolute;
  box-sizing: border-box;
}
.detailed-order-container .indented-address.passed::before {
  background-color: #58ca77;
  border: none;
}
.detailed-order-container .indented-address.incident::before {
  background-color: #fcbb3d;
  border: none;
}
.detailed-order-container .indented-address::before {
  height: 16px;
  width: 16px;
  left: -32px;
  top: 2px;
  border-radius: 50px;
  background-color: white;
  border: 2px solid #e6e6e6;
  z-index: 3;
}
.detailed-order-container .indented-address::after {
  height: calc(100% + 2rem);
  width: 4px;
  left: -26px;
  top: 12px;
  background-color: #e6e6e6;
  z-index: 2;
}
.detailed-order-container .indented-address.without-track::after {
  content: none !important;
}

.dd-automation-settings {
  width: 196px;
  left: 120px;
  top: -35px;
}

.checkout-details .border-wrapper {
  border-bottom: #eceff4 2px solid;
}
.checkout-details .border-wrapper .next-steps {
  margin: 1rem 1rem;
}
.checkout-details .border-wrapper .next-steps .title-header {
  font-size: 12px;
  color: black;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.checkout-details .border-wrapper .next-steps .title-header button {
  font-size: 12px;
  float: right;
  margin-top: 0;
  margin-right: 0;
}
.checkout-details .border-wrapper .next-steps .title-header button i {
  font-size: 14px;
  padding-left: 10px;
}
.checkout-details .border-wrapper .next-steps p {
  text-align: justify;
  font-size: 12px;
  line-height: 1.5;
}
.checkout-details .margin-wrapper {
  margin: 1rem 1rem;
}
.checkout-details .margin-wrapper .title-orders {
  font-size: 12px;
  color: black;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.checkout-details .margin-wrapper .list-orders {
  margin-bottom: 20px;
}
.checkout-details .margin-wrapper .list-orders .order {
  border: #eceff4 2px solid;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.checkout-details .margin-wrapper .list-orders .order .check-order {
  margin-top: 10px;
}
.checkout-details .margin-wrapper .list-orders .order .img-invoice {
  padding-top: 8px;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.checkout-details .margin-wrapper .list-orders .order .img-label {
  padding-top: 8px;
  padding-left: 5px;
}
.checkout-details .margin-wrapper .list-orders .order .order-content {
  padding-top: 8px;
  color: #778092;
}
.checkout-details .margin-wrapper .list-orders .order .order-content img {
  height: 8px;
  margin-left: 5px;
  margin-right: 5px;
  color: #778092;
}
.checkout-details
  .margin-wrapper
  .list-orders
  .order
  .order-content.order-icon
  img {
  height: 17px;
}
.checkout-details
  .margin-wrapper
  .list-orders
  .order
  .order-content.order-icon
  i {
  color: #2576da;
  font-size: 18px;
}
.checkout-details .margin-wrapper .list-orders .order label {
  font-size: 12px;
}
.checkout-details .margin-wrapper .btn-send {
  margin-right: 0;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  width: 10px;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

/* New era */
.conditions-container {
  margin-bottom: 1rem;
}
.conditions-container .add-filter-button-container {
  position: relative;
  display: inline;
}
.conditions-container
  .add-filter-button-container
  .add-filter-button.appending-filter
  + div:not(.filter-dd) {
  display: inline-block !important;
}
.conditions-container
  .add-filter-button-container
  .add-filter-button
  + div:not(.filter-dd) {
  display: none;
  margin-left: -1rem;
  transform: translateY(4px);
}

.filter-dd {
  width: 132px;
  left: 0;
}
.filter-dd .config-link {
  width: 100%;
  transition: 200ms;
  overflow: hidden;
}
.filter-dd .filter-container {
  max-height: 0;
  display: block;
  position: relative;
  height: auto;
  transition: 200ms;
  width: 100%;
  padding: 0.5rem 0.75rem 0 0.75rem;
}
.filter-dd.selected {
  width: 240px;
  overflow: visible;
}
.filter-dd.selected.filter-date {
  width: calc(450px + 1.5rem);
  max-height: 800px;
}
.filter-dd.selected.filter-date .filter-container {
  max-height: 800px;
}
.filter-dd.selected.filter-date div.input-container.open {
  max-height: 500px !important;
}
.filter-dd.selected .config-link {
  width: 0;
  height: 0;
  padding: 0;
}
.filter-dd.selected .config-link p:after {
  background: transparent;
}
.filter-dd.selected .filter-container {
  max-height: 500px;
}
.filter-dd.selected .filter-container .filter-title {
  color: #778092;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: 200ms;
  box-sizing: border-box;
  vertical-align: middle;
}
.filter-dd.selected .filter-container .filter-title img {
  margin-right: 4px;
}
.filter-dd.selected .filter-container .filter-title:hover {
  padding-left: 16px;
}
.filter-dd.selected .filter-container .filter-title:hover:before {
  left: 12px;
  opacity: 1;
}
.filter-dd.selected .filter-container .filter-title:before {
  font-family: "Ionicons";
  content: "\f3cf";
  color: #4a4a4a;
  font-size: 12pt;
  position: absolute;
  left: -8px;
  opacity: 0;
  transition: 200ms;
}
.filter-dd.selected .filter-container .filter-title:after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  background-color: #eceff4;
  left: 0;
  bottom: 2.25rem;
}
.filter-dd.selected .filter-container .fc-adapter {
  margin: 1rem 0;
}
.filter-dd.selected .filter-container *:not([type="link"]) {
  color: #4a4a4a;
}
.filter-dd.selected .filter-container .add-filter {
  width: 100%;
  box-sizing: border-box;
  margin: 0.75rem 0 !important;
}
.filter-dd.selected .filter-container .add-filter:before {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  background-color: #eceff4;
  left: 0;
  top: 36px;
}
.filter-dd.selected .filter-container ul {
  padding-top: 0.25rem;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 1rem;
}
.filter-dd.selected .filter-container ul li {
  list-style-type: none;
  position: relative;
  color: #4a4a4a;
  margin-bottom: 0.25rem;
}
.filter-dd.selected .filter-container ul li div.input-container {
  height: auto;
  max-height: 0;
  overflow: hidden;
  transition: 200ms;
}
.filter-dd.selected .filter-container ul li div.input-container.open {
  max-height: 80px;
  width: calc(100% + 1rem);
  margin-left: -1rem;
  overflow: visible;
}
.filter-dd.selected .filter-container ul li div.input-container.open > div {
  margin: 0 !important;
}
.filter-dd.selected
  .filter-container
  ul
  li
  div.input-container.open
  > div
  p[type="label"] {
  display: block;
}
.filter-dd.selected .filter-container ul li:hover:before {
  background-color: #cad2df;
}
.filter-dd.selected .filter-container ul li.selected:before {
  content: "\f383";
  background-color: #2576da;
  border: 1px solid #2576da;
}
.filter-dd.selected .filter-container ul li:before {
  font-family: "Ionicons";
  color: white;
  font-size: 8px;
  text-align: center;
  content: "";
  transition: 200ms;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100px;
  border: 1px solid #cad2df;
  display: block;
  position: absolute;
  left: -1rem;
  top: 0.25rem;
}

.actions-btns {
  display: block;
  width: 100%;
  height: auto;
  max-height: 0;
  transition: 200;
  overflow: hidden;
}
.actions-btns.open {
  max-height: 100px;
}

.card-shipments-container {
  text-align: center;
}
.card-shipments-container .card-shipment {
  display: inline-block;
  text-align: left !important;
  padding: 1rem !important;
  margin-bottom: 1rem;
  position: relative;
  width: calc(100% - 2rem);
}
.card-shipments-container .card-shipment .right-aligned {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.card-shipments-container .card-shipment .right-aligned p {
  text-align: right;
}
.card-shipments-container .card-shipment > div:not(.right-aligned) {
  margin-bottom: 0.75rem;
}
.card-shipments-container
  .card-shipment
  > div:not(.right-aligned):last-of-type {
  margin-bottom: 0;
}

.marketplaces-tab {
  float: left;
}

.btn-gray-download {
  background: #eceff4;
  border: 1.5px solid #cad2df;
  color: #000000;
  border-radius: 2px;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  letter-spacing: 0.44px;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0px;
  width: auto;
}

/*# sourceMappingURL=style.css.map */

.weight-number {
  color: "#BCC2C7";
}

.left {
  float: left;
}
.right {
  float: right;
  margin: 0px !important;
}
