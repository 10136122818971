.contPlan{
  width: 100vw;
  min-height: 100vh;
  background-color: rgba($color: #A4B5FF, $alpha: 0.41);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .cardLogin{
    min-height: 62vh;
    width: 946px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    .row{
      height: 100%;
      justify-content: space-between;
      padding: 24px;
    }
    .content {
      padding-top: 60px;
      padding-bottom: 90px;
    }
    .containerInputs{
      width: 80%;
      margin-left: 10%;
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        letter-spacing: 0.875px;
        margin-top: 0;
      }
      .mastercard{
        margin-left: 10px;
      }
    }
    .right{
      height: 100%;
      width: 100%;
      background-color: #101646;
      border-radius: 0px 10px 10px 0px;
      position: relative;
      display: flex;
      justify-content: center;
      .containerInputs {
        width: 70%;
        margin-left: 0;
        .card {
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 0px 5px rgba(0, 0, 0, 0.2);
          border-radius: 3px;
          padding: 0;
          .cardContent{
            padding-bottom: 50px;
            padding-top: 20px;
            .title{
              font-style: normal;
              font-weight: bold;
              font-size: 24px;
              line-height: 30px;
              color: #101646;
            }
            .price{
              font-style: normal;
              font-weight: bold;
              font-size: 50px;
              line-height: 63px;
              color: #101646;
              margin-bottom: 0;
              .perMonth{
                font-weight: normal;
                font-size: 20px !important;
                color: #101646;
              }
            }
            .usd{
              margin-top: 0;
              font-weight: normal;
              font-size: 18px;
              line-height: 23px;
              color: #101646;
              margin-bottom: 0;
            }
            .iva {
              margin-top: 5px;
              margin-bottom: 25px;
              font-family: Muli;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 15px;
              color: #8190A5;
            }
            .link{
              margin-top:20px;
              font-weight: 600;
              font-size: 13px;
              line-height: 16px;
              letter-spacing: 0.40625px;
              color: #2576DA !important;
              text-decoration: none;
            }
          }
          .gray{
            background: #F3F3F3;
            padding-top: 4px;
            padding-bottom: 4px;
          }
        }
      }
    }
  }
}

.marginTop {
  margin-top: 20px !important;
}

@media(max-width: 767px) {
  .card {
    border-style: hidden !important;
  }
  .expiration {
    width: 40% !important;
  }
  .right{
    .containerInputs{
      width: 100% !important;
    }
  }
}
