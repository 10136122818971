.error-container{
    background: white;
    height: 100vh;
    .logo{
        position: relative;
        float: right;
        width: 183px;
        height: auto;
        margin-top: 50px;
        margin-right: 30px;
    }
    .container-info{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
            color: #4A4A4A;
            font-size: 20px;
            
        }
        .error-btn{
            margin: 0px;
            margin-top: 10px;
            &.margin{
                margin-left: 25px;
            }
        }
    }
}