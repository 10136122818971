
.deleteOrder-leave {
  opacity: .3;
  height: 112px; /*requires modification*/
}

.deleteOrder-leave.deleteOrder-leave-active {
  opacity: 0;
  height: 0px;
  transition: all 300ms ease-in;
}

.showError-enter {
  opacity: 0;
  height:0px;
}

.showError-enter.showError-enter-active {
  opacity: 1;
  height: 44px;
  transition: all 300ms ease-in;
}

.showError-leave {
  opacity: 1;
  height:44px;
}

.showError-leave.showError-leave-active {
  opacity: 0;
  height: 0px;
  transition: all 300ms ease-in;
}

.toggleWallet-enter {
  opacity:0;
  height:0px;
  overflow: hidden;
}

.toggleWallet-enter.toggleWallet-enter-active {
  opacity:1;
  height: 244px;
  transition: all 600ms ease-in;
}

.toggleWallet-leave {
  opacity:1;
  height: 244px;
}

.toggleWallet-leave.toggleWallet-leave-active {
  opacity:0;
  height: 0px;
  overflow: hidden;
  transition: all 600ms ease-out;
}
