.selected-quote {
    position: relative;
    padding: 9px;
    box-sizing: border-box;
    height: 40px;
    margin-bottom: 8px;
    border-radius: 4px
}

.msg-label {
    font-size: 10pt;
    margin: 0px;
    padding: 0px;
}

.hidden .msg-label {
    visibility: hidden;
}

.error {
    color: red;
}

.success {
    color: green;
}