.sidebar {
    height: 100vh !important;
    padding: 26px 16px;
    box-sizing: border-box;

    .ion-android-close {
        display: none;
        color: #BCC2C7;
        position: relative;
        text-align: center;
        font-size: -webkit-xxx-large;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    @media screen and (max-width: 39.9375em) {
        width: 100px !important;
        position: fixed;
        left: -100px;
        top: 0 !important;
        z-index: 1100;
        transition: 200ms;
        background-color: #F3F4F6;

        &.mobile-menu-open {
            left: 0px;
        }

        .ion-android-close {
            display: block;
        }

        .sidebar-logo-container {
            display: none;

            +ul {
                li {
                    padding: 0;
                    margin-bottom: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 36px;
                        margin: 0;
                    }

                    &[type=selected],
                    &:hover {
                        background-color: transparent !important;
                    }
                }

                p {

                    &[type=textSidebarLink],
                    &[type=textSidebarLinkSelected] {
                        display: none;
                    }
                }
            }
        }

    }

    .sidebar-logo-container {
        width: 100%;
        text-align: center;
        margin-bottom: 42px;

        >span.prime {
            display: block;
            font-weight: bold;
        }
    }
}