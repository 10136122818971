.contInvoice{
  width: 100vw;
  min-height: 100vh;
  background-color: rgba($color: #A4B5FF, $alpha: 0.41);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .invoiceCard{
    width: 946px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    .row{
      height: 100%;
      justify-content: space-between;
      padding: 24px;
      .col {
        display: flex;
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;
        .containerInputs {
          width: 100%;
        }
      }
      .rowButton {
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
    .content {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

.margin-top {
  margin-top: 20px !important;
}

@media(max-width: 767px) {
  .card {
    border-style: hidden !important;
  }
  .expiration {
    width: 40% !important;
  }
  .right{
    .container-inputs{
      width: 100% !important;
    }
    .container-inputs-form {
      width: 80% !important;
    }
  }
}
