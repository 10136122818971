.title-container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown-accounts {
  margin-left: auto;
  margin-right: 5px;
}
.center-loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 2em;
}
