.table-rates{
    margin-top: 1rem;
}
.subacounts-list-container{
    overflow-x: scroll;
}
.filters-container-roles{
    display: flex;
    flex-direction: row;
    .drop-roles{
        width: 300px;
        margin-left: 1rem;
        margin-top: -1.5rem;
    }
}