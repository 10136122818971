.input-group {
    position: relative;
    margin-right: 15px;
    caret-color: transparent;
}

.icon {
    height: 1.5rem;
    width: 1.5rem;

    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    z-index: 3;
}

.input {
    text-indent: 1.7rem;
    caret-color: transparent;

}

.react-datepicker-popper {
    z-index: 3;
}

.close-icon {
    position: relative;
    left: -25px;
}